import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../services/storage.service';
import { LoginService } from '../../../services/login.service';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import { WaiverExecutiveManagement } from '../../../models/waiver-executive-management.model';
import { HttpErrorResponse } from '@angular/common/http';
import { WaiverExecutiveManagementStatus } from '../../../models/waiver-executive-management-status.model';
import { BudgetService } from '../../../services/budget.service';
import { GlobalBudget } from '../../..//models/global-budget.model';
import { MonthlyBudgetAgencyGroup } from '../../../models/monthly-budget-agency-group.model';
import { Waiver } from '../../../models/waiver.model';
import { animate, style, transition, trigger } from '@angular/animations';
import { WaiverRejectionDialogComponent } from '../../../components/waiver-rejection-dialog/waiver-rejection-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { WaiverRejectionMotive } from '../../../models/waiver-rejection-motive.model';

@Component({
  selector: 'app-executive-approval',
  templateUrl: './executive-approval.component.html',
  styleUrls: ['./executive-approval.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),  
        animate('300ms ease-in', style({ transform: 'translateY(0)', opacity: 1 })) 
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ transform: 'translateY(100%)', opacity: 0 })) 
      ])
    ])
  ]
})

export class ExecutiveApprovalComponent implements OnInit{

  isLogged: boolean = false;
  isLoadingAuth: boolean = false;
  isLoadingManagementInfo: boolean = false;
  loadingAgencyGroupBudget: boolean = false;
  loadingGlobalBudget: boolean = false;
  approvingWaiver: boolean = false;
  approvedWaiver: boolean = false;
  rejectingWaiver: boolean = false;
  rejectedWaiver: boolean = false;
  invalidStatus: boolean = false;


  executiveManagementInfo: WaiverExecutiveManagement;
  executiveManagementStatus: WaiverExecutiveManagementStatus;
  globalBudget: GlobalBudget;
  agencyGroupBudget: number;
  waiver: Waiver;
  rejectionMotives: WaiverRejectionMotive[];

  clientId: string = StorageService.getClientId();
  actionKey: string;


  statusMessages: any  = [
    {id: -2, msg: 'No se ha podido realizar la acción'},
    {id: -1, msg: 'No se ha encontrado la solicitud'},
    {id: 0, msg: 'Ha ocurrido un error al obtener la información'},
    {id: 2, msg: 'Esta solicitud ya ha sido gestionada'},
    {id: 3, msg: 'La gestión de esta solicitud ha expirado'},
  ];

  statusMessage: string;


  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private router: Router,
    private waiverService: WaiverPostsaleService,
    private budgetService: BudgetService,
    public dialog: MatDialog
  ) { 

  }

  ngOnInit() {
    this.obtainActionKey();
    if (this.actionKey != null){
      this.getWaiverByExecutiveManagement(this.actionKey);
    } else {
      console.log('error');
    }
  }

  obtainActionKey(){
    this.route.paramMap.subscribe(params => {
      this.actionKey = params.get('actionKey');
    });
  }

  getWaiverByExecutiveManagement(actionKey: string){
    this.isLoadingManagementInfo = true;
    this.waiverService.getWaiverByExecutiveManagement(actionKey).subscribe({
      next: (data: WaiverExecutiveManagement) => {
        this.executiveManagementInfo = data;
        this.waiver = this.executiveManagementInfo.waiver;

        if (!this.isManagementStatusValid(this.executiveManagementInfo.status)){
          this.manageInvalidStatus(this.executiveManagementInfo.status);
        } else {
          this.getAgencyGroupBudget(this.executiveManagementInfo);
          this.getGlobalBudget(this.executiveManagementInfo);
          this.getRejectionMotives();
        }
      }, 
      error: (err: HttpErrorResponse) => {
        if (err.status == 404){
          this.manageInvalidStatus(new WaiverExecutiveManagementStatus(-1, ''));
          this.invalidStatus = true;
        }
      }
    }).add( () => {
      this.isLoadingManagementInfo = false;
    })
  }


  getAgencyGroupBudget(executiveManagementInfo: WaiverExecutiveManagement){
    const groupId = executiveManagementInfo.waiver.agencyGroup.id;
    this.loadingAgencyGroupBudget = true;
    this.budgetService.getMonthlyAfterSalesBudgetByGroup(groupId).subscribe({
      next: (agencyBudget: MonthlyBudgetAgencyGroup) => {
        this.agencyGroupBudget = parseFloat((agencyBudget.assignedAmount - agencyBudget.busyAmount).toFixed(2));
      },
      error: (error: HttpErrorResponse) => {
        this.manageInvalidStatus(new WaiverExecutiveManagementStatus(0, ''));
        this.invalidStatus = true;
      }
    }).add( () => {
      this.loadingAgencyGroupBudget = false;
    });
  }

  getGlobalBudget(executiveManagementInfo: WaiverExecutiveManagement){
    let currentMonth: number = new Date().getMonth();
    this.loadingGlobalBudget = true;
    this.budgetService.getMarketGlobalBudgetForCurrentYear(executiveManagementInfo.waiver.market.name, 1).subscribe({
      next: (budget: GlobalBudget[]) => {
        this.globalBudget = budget.find(budget => budget.month == currentMonth);
      },
      error: (error: HttpErrorResponse) => {
        this.manageInvalidStatus(new WaiverExecutiveManagementStatus(0, ''));
        this.invalidStatus = true;
      }
    }).add( () => {
      this.loadingGlobalBudget = false;
    });
  }

  saveWaiverApprovedByExecutive(){
    this.approvingWaiver = true;

    this.approvedWaiver = true;
    this.waiverService.saveWaiverApprovedByExecutive(this.actionKey).subscribe({
      next: (waiver: Waiver) => {
        this.waiver = waiver;
        this.approvedWaiver = true;
      }, 
      error: (error: HttpErrorResponse) => {
        this.manageInvalidStatus(new WaiverExecutiveManagementStatus(-2, ''));
        this.invalidStatus = true;
      }
    }).add( () => {
      this.approvingWaiver = false;
    })
  }

  saveWaiverRejectedByExecutive(statusComment: string, rejectionMotive: WaiverRejectionMotive){
    this.rejectingWaiver = true;

    const waiverRejectionData: any = {
      rejectionMotiveId: rejectionMotive.id,
      rejectionComment: statusComment
    };

    this.waiverService.saveWaiverRejectedByExecutive(this.actionKey, waiverRejectionData).subscribe({
      next: (waiver: Waiver) => {
        this.waiver = waiver;
        this.rejectedWaiver = true;
      }, 
      error: (error: HttpErrorResponse) => {
        this.manageInvalidStatus(new WaiverExecutiveManagementStatus(-2, ''));
        this.invalidStatus = true;
      }
    }).add( () => {
      this.rejectingWaiver = false;
    });
  }

  confirmRejection(){
    let dialogRef = this.dialog.open(WaiverRejectionDialogComponent, {
      width: '600px',
      data: this.rejectionMotives
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result){
          this.saveWaiverRejectedByExecutive(result?.statusComment, result?.rejectionMotive);
        }
      }
    );
  }


   getRejectionMotives(): void {
    this.waiverService.getRejectionMotives().subscribe({
      next: (data: WaiverRejectionMotive[]) => {
        this.rejectionMotives = data;
      },
      error: (error: HttpErrorResponse) => {
        console.log('error', error);
      }
  });
  }
  
  isManagementStatusValid(status: WaiverExecutiveManagementStatus){
    return status.id != 2 && status.id !== 3;
  }

  manageInvalidStatus(status: WaiverExecutiveManagementStatus){
      this.statusMessage = this.statusMessages.find(msg => msg.id == status.id).msg;
      this.invalidStatus = true;
  }

}
