import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { LoginAgencyComponent } from './login-agency/login-agency.component';
import { LoginComponent } from './login.component';
import { RecoveryPassComponent } from './recovery-pass/recovery-pass.component';
import { ResetPassComponent } from './reset-pass/reset-pass.component';

import { ShareModule } from './../share-module/share.module';

import { LoginAgencyService } from './../../services/login-agency.service';
import { LoginLatamService } from './../../services/login-latam.service';
import { LoginService } from './../../services/login.service';
import { LoginLatamComponent } from './login-latam/login-latam.component';
import { LoginLatamMobileComponent } from './login-latam-mobile/login-latam-mobile.component';

@NgModule({
  declarations: [
    LoginAgencyComponent,
    LoginComponent,
    RecoveryPassComponent,
    ResetPassComponent,
    LoginLatamComponent,
    LoginLatamMobileComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    ShareModule
  ],
  exports: [
    LoginComponent
  ],
  providers: [
    LoginAgencyService,
    LoginLatamService,
    LoginService
  ]
})
export class LoginModule { }
