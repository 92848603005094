import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WaiverRejectionMotive } from '../../models/waiver-rejection-motive.model';

@Component({
  selector: 'app-waiver-rejection-dialog',
  templateUrl: './waiver-rejection-dialog.component.html',
  styleUrls: ['./waiver-rejection-dialog.component.css']
})
export class WaiverRejectionDialogComponent implements OnInit{


  rejectionForm: FormGroup;
  rejectionMotive: string;
  statusComment: string;
  rejectionMotives: WaiverRejectionMotive[];
  confirmingRejection: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<WaiverRejectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,) {
      this.rejectionMotives = data;
    }

  ngOnInit(): void {
    this.rejectionForm = new FormGroup({
      rejectionMotive: new FormControl('', Validators.required),
      statusComment: new FormControl('', Validators.required)
    });
  }
    

  confirmRejection(form){
    if (this.rejectionForm.valid){
      console.log('is valid');
      this.dialogRef.close(
        {
          statusComment: this.rejectionForm.get('statusComment').value,
          rejectionMotive: this.rejectionForm.get('rejectionMotive').value
        }
      );
    }
  }

  cancel(){
    this.rejectionForm.reset();
    this.dialogRef.close();
  }



}
