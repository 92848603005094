
import { Observable, from as observableFrom, of } from 'rxjs';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, delay, map, mergeMap, retryWhen, scan, share, tap } from 'rxjs/operators';






import { environment } from '../../environments/environment';

import { WaiverLatamPostsaleInterface } from '../interfaces/waiver-latam-postsale.interface';

import { WaiverPage } from '../models/waiver-page.model';
import { WaiverRejectionMotive } from '../models/waiver-rejection-motive.model';
import { WaiverToken } from '../models/waiver-token.model';
import { Waiver } from '../models/waiver.model';
import { WaiverMotive } from './../models/waiver-motive.model';
import { WaiverTypeStatus } from './../models/waiver-type-status.model';
import { WaiverType } from './../models/waiver-type.model';

import { WaiverAgencyCorrection } from '../interfaces/waiver-agency-correction.interface';
import { WaiverPendingAgency } from '../interfaces/waiver-pending-agency.interface';
import { PaginatorWaiverPostSale } from '../models/paginator-waiver-postsale';
import { RouteLocaleExtractorService } from './route-locale-extractor.service';
import { SessionInfoService } from './session-info.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { StorageService } from './storage.service';
import { CurrencyConverter } from '../interfaces/currency-converter.interface';
import { NewWaiverAlterationRQ } from '../interfaces/new-waiver-alteration-rq';
import { WaiverExecutiveManagement } from '../models/waiver-executive-management.model';


@Injectable()
export class WaiverPostsaleService {
  headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlWaiver}`;
  // urlBase = `http://localhost:9090/http://localhost:8060/waiver`;
  readonly waiversOutsourcing: string = '/waivers/outsourcing/market';
  waiver: any;

  constructor(
    private http: HttpClient,
    private routeLocaleExtractorService: RouteLocaleExtractorService,
    private sessionInfoService: SessionInfoService) { }

  initForm(){
    return new UntypedFormGroup({
      idRequest: new UntypedFormControl(null,[Validators.pattern("[0-9]+")]),
      pos:  new UntypedFormControl('', [Validators.pattern("[a-zA-Z]+"), Validators.minLength(2),Validators.maxLength(2)]),
      agency: new UntypedFormControl(''),
      iata: new UntypedFormControl('',  [Validators.pattern("[0-9]+"), Validators.minLength(8),Validators.maxLength(8)]),
      requestDate: new UntypedFormControl(''),
      salesEjecutive: new UntypedFormControl(''),
      type: new UntypedFormControl(''),
      motive: new UntypedFormControl(''),
      pnrTkt: new UntypedFormControl(''),
      flightDate:new UntypedFormControl('')


    });
  }

  countPendingWaivers(waiver:WaiverPage): Observable<any> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const parametros = this.prepareParams(waiver);

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(session => {
      let marketId = session.marketId;
      let role = session.role;
      let userId = session.userId;
      let url = '';

      if (role === 'ADMIN_SALES') {
        url = `${this.urlBase}${locale}/waivers/matrix/pending/count?adminSalesId=${userId}`;
      } else if (role === 'USER_SALES') {
        url = `${this.urlBase}${locale}/waivers/matrix/pending/count?userSalesId=${userId}`;
      } else {
        url = `${this.urlBase}${locale}/waivers/market/pending/count?markets=${marketId}`;
      }

      return this.http.get(url, { params: parametros }).pipe(
        map(data => {
          return data;
        }));
    })).pipe(
      catchError(error => of(console.log('error', error)))
    );
  }

   prepareParams(waiver:WaiverPage){
    let idValidation= waiver.id;
    if (idValidation==null){

      idValidation=0;
    }
    let requestDate;
    let flightDate;

    if (waiver.flightDate===null || waiver.flightDate==='' ){

      flightDate = 0;

    }else{

      flightDate = waiver.flightDate;

    }
    if (waiver.requestDate===null || waiver.requestDate==='' ){

       requestDate = 0;

    }else{

       requestDate = waiver.requestDate;

    }
    return new HttpParams()
      .set('id', idValidation + '')
      .set('pos', waiver.pos + '')
      .set('agency', waiver.agency + '')
      .set('iata', waiver.iata + '')
      .set('requestDate', requestDate + '')
      .set('salesEjecutive', waiver.salesEjecutive + '')
      .set('type', btoa(waiver.type)+'')
      .set('motive', btoa(waiver.motive+''))
      .set('pnrTkt', waiver.pnrTkt + '')
      .set('flightDate', flightDate + '');
  }

  countApprovedWaivers(waiver:WaiverPage): Observable<any> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const parametros = this.prepareParams(waiver);

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(session => {
      let marketId = session.marketId;
      let role = session.role;
      let userId = session.userId;
      let url = '';

      if (role === 'ADMIN_SALES') {
        url = `${this.urlBase}${locale}/waivers/matrix/approved/count?adminSalesId=${userId}`;
      } else if (role === 'USER_SALES') {
        url = `${this.urlBase}${locale}/waivers/matrix/approved/count?userSalesId=${userId}`;
      } else {
        url = `${this.urlBase}${locale}/waivers/market/approved/count?markets=${marketId}`;
      }

      return this.http.get(url, { params: parametros }).pipe(
        map(data => {
          return data;
        }));
    })).pipe(
      catchError(error => of(console.log('error', error)))
    );
  }
  countRejectedWaivers(waiver:WaiverPage): Observable<any> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const parametros = this.prepareParams(waiver);

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(session => {
      let marketId = session.marketId;
      let role = session.role;
      let userId = session.userId;
      let url = '';

      if (role === 'ADMIN_SALES') {
        url = `${this.urlBase}${locale}/waivers/matrix/rejected/count?adminSalesId=${userId}`;
      } else if (role === 'USER_SALES') {
        url = `${this.urlBase}${locale}/waivers/matrix/rejected/count?userSalesId=${userId}`;
      } else {
        url = `${this.urlBase}${locale}/waivers/market/rejected/count?markets=${marketId}`;
      }

      return this.http.get(url , { params: parametros }).pipe(
        map(data => {
          return data;
        }));
    })).pipe(
      catchError(error => of(console.log('error', error)))
    );
  }

  getApprovedWaiverList(limit:number, offset:number, waiver:WaiverPage): Observable<WaiverLatamPostsaleInterface[]> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const parametros = this.prepareParams(waiver);

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let marketId = session.marketId;
        let role = session.role;
        let userId = session.userId;
        let url = '';

        if (role === 'ADMIN_SALES') {
          url = `${this.urlBase}${locale}/waivers/user/sales/status/2?adminSalesId=${userId}&size=${limit}&page=${offset}`;
        } else if (role === 'USER_SALES') {
          url = `${this.urlBase}${locale}/waivers/user/sales/status/2?userSalesId=${userId}&size=${limit}&page=${offset}`;
        } else if (role === 'USER_EXT'){
          url = `${this.urlBase}${locale}${this.waiversOutsourcing}/approved`;
        } else {
          url = `${this.urlBase}${locale}/waivers/market/approved?markets=${marketId}&size=${limit}&page=${offset}`;
        }

        if (role === 'USER_EXT'){
          const params = this.getParams(marketId, offset, limit);
          const waiverListFilter: WaiverPage = this.formatWaiverSearch(+parametros.get('id'), waiver, parametros.get('requestDate'), parametros.get('flightDate'));
          return this.http.post<WaiverLatamPostsaleInterface[]>(url,waiverListFilter, { params }).pipe(map((data: any) => data.content));
        } else {
          return this.http.get<WaiverLatamPostsaleInterface[]>(url, { params: parametros });
        }
      }
    ));
  }

  private getParams(marketId, page, size): HttpParams{
    return new HttpParams()
          .set('markets', marketId.toString())
          .set('page', page.toString())
          .set('size', size.toString());
  }

  getWaiver(pageSize: number, numPage: number, params: HttpParams, statusName: string, user: string): Observable<PaginatorWaiverPostSale>{
    if (user === 'agent'){
      return this.getWaiverAgent(pageSize, numPage, params, statusName);
    }
    if (statusName === 'aprobados') {
      return this.getApprovedWaiverListAgency(pageSize, numPage, params);
    } else if (statusName === 'rechazados') {
      return this.getRejectedWaiverListAgency(pageSize, numPage, params);
    } else if (statusName === 'pendientes') {
      return this.getPendingWaiverListAgency(pageSize, numPage, params);
    }
    return this.getPendingAgencyWaiverList(pageSize, numPage, params);

  }
  getWaiverAgent(pageSize: number, numPage: number, params: HttpParams, statusName: string): Observable<PaginatorWaiverPostSale> {
    if (statusName === 'aprobados') {
      return this.getApprovedWaiverListAgent(pageSize, numPage, params);
    } else if (statusName === 'rechazados') {
      return this.getRejectedWaiverListAgent(pageSize, numPage, params);
    }
    return this.getPendingWaiverListAgent(pageSize, numPage, params);

  }

  getApprovedWaiverListAgency(pageSize: number, numPage: number, params: HttpParams): Observable<PaginatorWaiverPostSale> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let role = session.role;
        let matrixId = session.matrix_id;
        let branchOfficeId = session.branch_office_id;
        let url = '';


        if ((role === 'ADMIN_MTZ' || role === 'MANUAL_MTZ') && matrixId) {
          url = `${this.urlBase}${locale}/waivers/status/2?matrixId=${matrixId}&sizePage=${pageSize}&numPage=${numPage}`;
        } else if (role === 'ADMIN_SUC' && branchOfficeId) {
          url = `${this.urlBase}${locale}/waivers/status/2?branchOfficeId=${branchOfficeId}&sizePage=${pageSize}&numPage=${numPage}`;
        }

        return this.http.get<PaginatorWaiverPostSale>(url,{params});
      }
    ));
  }

  getApprovedWaiverListAgent(limit:number, offset:number, params: HttpParams): Observable<PaginatorWaiverPostSale> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let role = session.role;
        let userId = session.userId;
        let matrixId = session.matrix_id;
        let branchOfficeId = session.branch_office_id;
        let url = '';

        if (null === matrixId) {
          url = `${this.urlBase}${locale}/waivers/agent/${userId}/approved?size=${limit}&page=${offset}`;
          return this.http.get<PaginatorWaiverPostSale>(url,{params});
        } else if (role === 'AGENT_MTZ' && matrixId) {
          url = `${this.urlBase}${locale}/waivers/status/2?matrixId=${matrixId}&sizePage=${limit}&numPage=${offset}`;
          return this.http.get<PaginatorWaiverPostSale>(url,{params});
        } else if (role === 'AGENT_SUC' && branchOfficeId) {
          // tslint:disable-next-line: max-line-length
          url = `${this.urlBase}${locale}/waivers/branchoffice/user/status/2?branchOfficeId=${branchOfficeId}&userId=${userId}&sizePage=${limit}&numPage=${offset}`;
          return this.http.get<PaginatorWaiverPostSale>(url,{params});
        }

      }
    ));
  }

  /**
   * returns the list of available reasons for a particular type
   * @param idType identifier of the type that is consulted
   */
  getMotives(idType: number): Observable<WaiverMotive> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get(`${this.urlBase}${locale}/waivers/types/${idType}/motives`).pipe(
      map(
        data => {
          return data['waiverMotives'];
        }
      ));
  }

  getPendingWaiverList(limit:number, offset:number, waiver:WaiverPage): Observable<WaiverLatamPostsaleInterface[]> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const parametros = this.prepareParams(waiver);

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let marketId = session.marketId;
        let role = session.role;
        let userId = session.userId;
        let url = '';

        if (role === 'ADMIN_SALES') {
          url = `${this.urlBase}${locale}/waivers/user/sales/status/1?adminSalesId=${userId}&size=${limit}&page=${offset}`;
        } else if (role === 'USER_SALES') {
          url = `${this.urlBase}${locale}/waivers/user/sales/status/1?userSalesId=${userId}&size=${limit}&page=${offset}`;
        } else if (role === 'USER_EXT'){
          url = `${this.urlBase}${locale}${this.waiversOutsourcing}/pending`;
        }
        else {
          url = `${this.urlBase}${locale}/waivers/market/pending?markets=${marketId}&size=${limit}&page=${offset}`;
        }

        if (role === 'USER_EXT'){
          const params = this.getParams(marketId, offset, limit);
          const waiverListFilter: WaiverPage = this.formatWaiverSearch(+parametros.get('id'), waiver, parametros.get('requestDate'), parametros.get('flightDate'));
          return this.http.post<WaiverLatamPostsaleInterface[]>(url,waiverListFilter, { params }).pipe(map((data: any) => data.content));
        } else {
          return this.http.get<WaiverLatamPostsaleInterface[]>(url, { params: parametros });
        }
      }
    ));
  }

  getPendingWaiverListAgency(pageSize: number, numPage: number, params: HttpParams): Observable<PaginatorWaiverPostSale> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let role = session.role;
        let matrixId = session.matrix_id;
        let branchOfficeId = session.branch_office_id;
        let url = '';

        if (role === 'ADMIN_MTZ' && matrixId) {
          url = `${this.urlBase}${locale}/waivers/status/1?matrixId=${matrixId}&sizePage=${pageSize}&numPage=${numPage}`;
        } else if (role === 'MANUAL_MTZ' && matrixId){
          url = `${this.urlBase}${locale}/waivers/manual/status/1?matrixId=${matrixId}&sizePage=${pageSize}&numPage=${numPage}`;
        }
         else if (role === 'ADMIN_SUC' && branchOfficeId) {
          url = `${this.urlBase}${locale}/waivers/status/1?branchOfficeId=${branchOfficeId}&sizePage=${pageSize}&numPage=${numPage}`;
        }

        return this.http.get<PaginatorWaiverPostSale>(url, {params});
      }
    ));
  }

  getPendingAgencyWaiverList(pageSize: number, numPage: number, params: HttpParams): Observable<PaginatorWaiverPostSale> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let role = session.role;
        let matrixId = session.matrix_id;
        let branchOfficeId = session.branch_office_id;
        let url = '';

        if ((role === 'ADMIN_MTZ' || role === 'MANUAL_MTZ') && matrixId) {
          url = `${this.urlBase}${locale}/waivers/status/8?matrixId=${matrixId}&sizePage=${pageSize}&numPage=${numPage}`;
        } else if (role === 'ADMIN_SUC' && branchOfficeId) {
          url = `${this.urlBase}${locale}/waivers/status/8?branchOfficeId=${branchOfficeId}&sizePage=${pageSize}&numPage=${numPage}`;
        }

        return this.http.get<PaginatorWaiverPostSale>(url, {params});
      }
    ));
  }

  getPendingWaiverListAgent(limit:number, offset:number, params: HttpParams): Observable<PaginatorWaiverPostSale> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let role = session.role;
        let userId = session.userId;
        let matrixId = session.matrix_id;
        let branchOfficeId = session.branch_office_id;
        let url = '';

        if (null === matrixId) {
          url = `${this.urlBase}${locale}/waivers/agent/${userId}/pending?size=${limit}&page=${offset}`;
          return this.http.get<PaginatorWaiverPostSale>(url, {params});
        } else if (role === 'AGENT_MTZ' && matrixId) {
          url = `${this.urlBase}${locale}/waivers/status/1?matrixId=${matrixId}&sizePage=${limit}&numPage=${offset}`;
          return this.http.get<PaginatorWaiverPostSale>(url, {params});
        } else if (role === 'AGENT_SUC' && branchOfficeId) {
          url = `${this.urlBase}${locale}/waivers/branchoffice/user/status/1?branchOfficeId=${branchOfficeId}&userId=${userId}&sizePage=${limit}&numPage=${offset}`;
          return this.http.get<PaginatorWaiverPostSale>(url, {params});
        }

      }
    ));
  }

  getWaiverValue(request: NewWaiverAlterationRQ){
    const locale = this.routeLocaleExtractorService.getLocale();
    const httpParams = new HttpParams()
              .set("destinationAirport", request.destinationAirport)
              .set("originAirport", request.originAirport)
              .set("farebasis", request.farebasis)
              .set("waiverTypeCode", request.waiverTypeCode)
              .set("flightDate", request.flightDate.toISOString())
              .set("todayDate", request.todayDate.toISOString());
    return this.http.get(`${this.urlBase}/alteration/value`, {params: httpParams });
  }

  /**
   * returns the information of a pnr
   * @param pnrNumber identifier of the pnr to consult
   */
  getPnr(pnrNumber: string): Observable<Waiver> {
    const locale = this.routeLocaleExtractorService.getLocale();
    return this.http.get(`${this.urlBase}${locale}/waivers/pnr/${pnrNumber}`).pipe(
      map(
        data => {
          this.waiver = {
            pnr: data['pnr'],
            booking: data['booking'],
            passengers: data['passengers'],
            segments: data['segments'],
            osiInfo: data['osiInfo'],
            origin: data['origin'],
            destination: data['destination']
          };


          return this.waiver;
        }
      ),
      retryWhen(error =>
        error.pipe(
          scan((acc, error) => {
            if (acc > 2) throw error;
            console.log("Intento: " + acc);
            return acc + 1;
          }, 1),
          delay(1000),
          tap(() => console.log("Intentando ..."))
        )
      ),)
  }

  /**
   * returns the information of a ticket
   * @param tktNumber identifier of the ticket to consult
   */
  getPnrTkt(tktNumber: string): Observable<Waiver> {
    const locale = this.routeLocaleExtractorService.getLocale();
    return this.http.get<Waiver>(`${this.urlBase}${locale}/waivers/pnr/ticket/${tktNumber}`).pipe(
      map(
        data => {
          console.log("Mostrar data");
          console.log(data.coupons.length);
          this.waiver = {
            tkt: data['tkt'],
            coupons: data['coupons']
          };
          return this.waiver;
        }
      ),
      retryWhen(error =>
        error.pipe(
          scan((acc, error) => {
            if (acc > 2) throw error;
            console.log("Intento: " + acc);
            return acc + 1;
          }, 1),
          delay(1000),
          tap(() => console.log("Intentando ..."))
        )
      ),)
  }

  getRejectedWaiverList(limit:number, offset:number, waiver:WaiverPage): Observable<WaiverLatamPostsaleInterface[]> {
    const locale = this.routeLocaleExtractorService.getLocale();
    const parametros = this.prepareParams(waiver);
    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let marketId = session.marketId;
        let role = session.role;
        let userId = session.userId;
        let url = '';

        if (role === 'ADMIN_SALES') {
          url = `${this.urlBase}${locale}/waivers/user/sales/status/3?adminSalesId=${userId}&size=${limit}&page=${offset}`;
        } else if (role === 'USER_SALES') {
          url = `${this.urlBase}${locale}/waivers/user/sales/status/3?userSalesId=${userId}&size=${limit}&page=${offset}`;
        } else if (role === 'USER_EXT'){
          url = `${this.urlBase}${locale}${this.waiversOutsourcing}/rejected`;
        } else {
          url = `${this.urlBase}${locale}/waivers/market/rejected?markets=${marketId}&size=${limit}&page=${offset}`;
        }

        if (role === 'USER_EXT'){
          const params = this.getParams(marketId, offset, limit);
          const waiverListFilter: WaiverPage = this.formatWaiverSearch(+parametros.get('id'), waiver, parametros.get('requestDate'), parametros.get('flightDate'));
          return this.http.post<WaiverLatamPostsaleInterface[]>(url,waiverListFilter, { params }).pipe(map((data: any) => data.content));
        } else {
          return this.http.get<WaiverLatamPostsaleInterface[]>(url, { params: parametros });
        }
      }
    ));
  }

  private formatWaiverSearch(id: number, waiver: WaiverPage, requestDate: string | number, flightDate: string | number): WaiverPage {
    return {...waiver, flightDate, id, requestDate};
  }

  getRejectedWaiverListAgency(pageSize: number, numPage: number, params: HttpParams): Observable<PaginatorWaiverPostSale> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let role = session.role;
        let matrixId = session.matrix_id;
        let branchOfficeId = session.branch_office_id;
        let url = '';
        console.log(session.matrix_id);

        if ((role === 'ADMIN_MTZ' || role === 'MANUAL_MTZ') && matrixId) {
          url = `${this.urlBase}${locale}/waivers/status/3?matrixId=${matrixId}&sizePage=${pageSize}&numPage=${numPage}`;
        } else if (role === 'ADMIN_SUC' && branchOfficeId) {
          url = `${this.urlBase}${locale}/waivers/status/3?branchOfficeId=${branchOfficeId}&sizePage=${pageSize}&numPage=${numPage}`;
        }

        return this.http.get<PaginatorWaiverPostSale>(url,{params});
      }
    ));
  }

  getRejectedWaiverListAgent(limit:number, offset:number, params: HttpParams): Observable<PaginatorWaiverPostSale> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let role = session.role;
        let userId = session.userId;
        let matrixId = session.matrix_id;
        let branchOfficeId = session.branch_office_id;
        let url = '';

        if (null === matrixId) {
          url = `${this.urlBase}${locale}/waivers/agent/${userId}/rejected?size=${limit}&page=${offset}`;
          return this.http.get<PaginatorWaiverPostSale>(url, {params});
        } else if (role === 'AGENT_MTZ' && matrixId) {
          url = `${this.urlBase}${locale}/waivers/status/3?matrixId=${matrixId}&sizePage=${limit}&numPage=${offset}`;
          return this.http.get<PaginatorWaiverPostSale>(url, {params});
        } else if (role === 'AGENT_SUC' && branchOfficeId) {
          url = `${this.urlBase}${locale}/waivers/branchoffice/user/status/3?branchOfficeId=${branchOfficeId}&userId=${userId}&sizePage=${limit}&numPage=${offset}`;
          return this.http.get<PaginatorWaiverPostSale>(url, {params});
        }


      }
    ));
  }

  /**
   * returns the list of reasons for rejection
   */
  getRejectionMotives(): Observable<WaiverRejectionMotive[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get(`${this.urlBase}${locale}/waivers/rejection-motives`).pipe(
      map(
        data => {
          return data['waiverRejectionMotives'];
        }
      ));
  }

  /**
   * returns the information of a ticket
   * @param tktNumber identifier of the ticket to consult
   */
  getTkt(tktNumber: string): Observable<Waiver> {
    const locale = this.routeLocaleExtractorService.getLocale();
    return this.http.get(`${this.urlBase}${locale}/waivers/ticket/${tktNumber}`).pipe(
      map(
        data => {
          this.waiver = {
            tkt: data['tkt'],
            issuer: data['issuer'],
            booking: data['booking'],
            coupons: data['coupons'],
            passenger: data['passenger'],
            remark: data['remark']
          };

          return this.waiver;
        }
      ),
      retryWhen(error =>
        error.pipe(
          scan((acc, error) => {
            if (acc > 2) throw error;
            console.log("Intento: " + acc);
            return acc + 1;
          }, 1),
          delay(1000),
          tap(() => console.log("Intentando ..."))
        )
      ),)
  }

  /**
   * returns a list with all types of waivers available
   */
  getTypes(): Observable<WaiverType[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get(`${this.urlBase}${locale}/waivers/types`).pipe(
    map(
      data => {
        return data['waiverTypes'];
      }
    ));
  }

  getTypesByMarket(marketId: number): Observable<WaiverType[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    let waiverType: WaiverType[] = [];

    return this.http.get(
      `${this.urlBase}${locale}/waivers/types/status/filtered?market=${marketId}`
    ).pipe(
    map(
      (data: WaiverType[]) => {
        data.forEach(
          (value: WaiverType) => {
            waiverType.push(value);
          }
        );

        return waiverType;
      }
    ));
  }

  /**
   * Method that consults the rest service to consult the detail of a specific waiver
   * @param id Id of the waiver that you wish to consult
   * shows an observable list <Waiver> with the requested waiver detail
   * in case there are no matches returns an empty list
   */
  getWaiverById(id: number): Observable<Waiver> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.get<Waiver>(`${this.urlBase}${locale}/waivers/${id}`);
  }

  getWaiverTypeStatusByMaketId(marketId: number): Observable<WaiverTypeStatus[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    let waiverTypeStatus: WaiverTypeStatus[] = [];

    return this.http.get(
      `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlWaiver}${locale}/waivers/types/status?market=${marketId}`
    ).pipe(
    map(
      (data: WaiverTypeStatus[]) => {
        data.forEach(
          (value: WaiverTypeStatus) => {
            waiverTypeStatus.push(new WaiverTypeStatus(value.id, value.waivertype, value.market, value.status));
          }
        );

        return waiverTypeStatus;
      }
    ));
  }

  updateWaiverTypeStatusList(waiverTypeStatus: WaiverTypeStatus[]): Observable<WaiverTypeStatus[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<WaiverTypeStatus[]>(
      `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlWaiver}${locale}/waivers/types/status`,
      waiverTypeStatus,
      { headers: this.headers }
    ).pipe(
    map(response => response));
  }

  /**
   * Method that consults the rest service to approve a waiver
   * @param id Id of the waiver you want to approve
   * @param comment additional comment of the approval
   * @param folio folio waiver
   * @param value value waiver
   * @returns `Observable<Waiver>` approved waiver
   */
  putApproveWaiver(id: number, comment: string, folio: string, value: string) {
    const locale = this.routeLocaleExtractorService.getLocale();

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let role = session.role;

        if (role === 'MANUAL_MTZ') {
          return this.http.put(
            `${this.urlBase}${locale}/waivers/${id}/manual/approve`,
            { additionalComment: comment, folioWaiver: folio, valorization: value },
            { headers: this.headers }
          ).pipe(
          map(
            data => {
              this.waiver = data;
              return this.waiver;
            }
          ));
        } else {
          return this.http.put(
            `${this.urlBase}${locale}/waivers/${id}/approve`,
            { additionalComment: comment, folioWaiver: folio, valorization: value },
            { headers: this.headers }
          ).pipe(
          map(
            data => {
              this.waiver = data;
              return this.waiver;
            }
          ));
        }


      }
    ));
  }

  /**
   * Method that consults the rest service to reject a waiver
   * @param id Id of the waiver to be rejected
   * @param motiveId Id of the rejection reason of the waiver
   * @param comment additional rejection comment
   * returns `Observable<Waiver>`, waiver rejected
   */
  putRejectWaiver(id: number, motiveId: number, comment: string) {
    const locale = this.routeLocaleExtractorService.getLocale();

    return observableFrom(this.sessionInfoService.getSessionInfoAsPromise()).pipe(mergeMap(
      session => {
        let role = session.role;

        if (role === 'MANUAL_MTZ') {
          return this.http.put(
            `${this.urlBase}${locale}/waivers/${id}/manual/reject`,
            { rejectionMotiveId: motiveId, rejectionComment: comment },
            { headers: this.headers }
          ).pipe(
          map(
            data => {
              this.waiver = data;
              return this.waiver;
            }
          ));
        } else {
          return this.http.put(
            `${this.urlBase}${locale}/waivers/${id}/reject`,
            { rejectionMotiveId: motiveId, rejectionComment: comment },
            { headers: this.headers }
          ).pipe(
          map(
            data => {
              this.waiver = data;
              return this.waiver;
            }
          ));
        }


      }
    ));
  }

  setPendingAgencyWaiver(waiverPendingAgency: WaiverPendingAgency, waiverId: number): Observable<any>{
    const locale = this.routeLocaleExtractorService.getLocale();
    const url = `${this.urlBase}${locale}/waivers/${waiverId}/pending-agency`;
    return this.http.put(url, waiverPendingAgency, {headers: this.headers});
  }

  putWaiverPendingCorrection(waiverId: number, waiverAgencyCorrection: WaiverAgencyCorrection): Observable<any>{
    const locale = this.routeLocaleExtractorService.getLocale();
    console.log(waiverId);
    const url = `${this.urlBase}${locale}/waivers/${waiverId}/pending-agency/correction`;
    return this.http.put(url, waiverAgencyCorrection, {headers: this.headers});
  }

  /**
   * Method that calls the rest service to calculate a waiver
   * @param waiver
   */
  calculateWaiver(waiver: Waiver): Observable<number> {
    const locale = this.routeLocaleExtractorService.getLocale();

    let agentId = this.sessionInfoService.getSessionInfo().userId;

    waiver.user = {
      id: agentId
    };


    return this.http.post<any>(`${this.urlBase}${locale}/waiver/valorization/calculate-jwt`, waiver, { headers: this.headers }).pipe(share());
  }

  saveWaiver(waiver: Waiver): Observable<Waiver> {
    const locale = this.routeLocaleExtractorService.getLocale();

    let agentId = this.sessionInfoService.getSessionInfo().userId;

    waiver.user = {
      id: agentId
    };

    return this.http.post<Waiver>(`${this.urlBase}${locale}/waivers`, waiver, { headers: this.headers }).pipe(share());
  }

  saveListWaiver(waiverList: Waiver[]): Observable<Waiver[]> {
    const locale = this.routeLocaleExtractorService.getLocale();

    let agentId = this.sessionInfoService.getSessionInfo().userId;

    waiverList.forEach( waiver => {
      waiver.user = {id: agentId}
    })

    return this.http.post<Waiver[]>(`${this.urlBase}${locale}/list/waivers`, waiverList, { headers: this.headers }).pipe(share());
  }

  /**
   * Method that calls the rest service to add a waiver to the database
   * @param waiver
   */
  saveWaiverJwt(waiverToken: WaiverToken): Observable<Waiver> {
    const locale = this.routeLocaleExtractorService.getLocale();

    return this.http.post<Waiver>(`${this.urlBase}${locale}/waivers-jwt`, waiverToken, { headers: this.headers }).pipe(share());
  }

  changeCurrencyWaiverAmount(currencyConverter: CurrencyConverter){
    const locale = this.routeLocaleExtractorService.getLocale();
    let params = new HttpParams()
      .append("sourceCurrencyIsoCode", currencyConverter.sourceCurrencyIsoCode)
      .append("destinationCurrencyIsoCode", currencyConverter.destinationCurrencyIsoCode);

    //const urlLocal = 'http://localhost:9090/http://localhost:8060/waiver';
    return this.http.get(`${this.urlBase}${locale}/change-rate`, { headers: this.headers, params}).pipe(share());
  }

  saveWaiverAutoApproved(waiver: Waiver){
    const locale = this.routeLocaleExtractorService.getLocale();
    let agentId = this.sessionInfoService.getSessionInfo().userId;

    waiver.user = {
      id: agentId
    };

    //const urlLocal = 'http://localhost:9090/http://localhost:8060/waiver';
    return this.http.post(`${this.urlBase}${locale}/waivers/auto-approved`, waiver, {headers: this.headers}).pipe(share());
  }

  saveWaiverApprovedByExecutive(actionKey: string){
    //const urlLocal = 'http://localhost:9090/http://localhost:8060/waiver';
    return this.http.put(`${this.urlBase}/waivers/executive/management/${actionKey}/approve`, {headers: this.headers}).pipe(share());
  }

  saveWaiverRejectedByExecutive(actionKey: string, rejectionData: any){
    //const urlLocal = 'http://localhost:9090/http://localhost:8060/waiver';
    return this.http.put(`${this.urlBase}/waivers/executive/management/${actionKey}/reject`, rejectionData, {headers: this.headers}).pipe(share());
  }

  getWaiverByExecutiveManagement(actionKey: string): Observable<WaiverExecutiveManagement>{
    //const urlLocal = 'http://localhost:9090/http://localhost:8060/waiver';
    return this.http.get<WaiverExecutiveManagement>(`${this.urlBase}/waivers/executive/management/${actionKey}`, {headers: this.headers}).pipe(share());
  }





}
