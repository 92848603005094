import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable()
export class LoginService {
  private loginSubject = new BehaviorSubject<boolean>(false);
  loginState = this.loginSubject.asObservable();

  public setCurrentLoginState(value: boolean): void {
    this.loginSubject.next(value);
  }
}
