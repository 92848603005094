<div class="login-container">
  <div class="login-header">
      <img src="assets/images/logo-latamtrade.svg" alt="Logo Latamtrade">
  </div>

  <div class="login-content">
      <div class="login-card">
          <p>
              Para acceder a este servicio debes ingresar
              con tu usuario y contraseña LATAM
          </p>
          <div class="login-button">
              <div id="g_id_onload"
                  [attr.data-client_id]="clientId"
                  data-auto_prompt="false"
                  data-callback="handleCredentialResponse">
                  </div>
              <div class="g_id_signin"
                  data-size="large"
                  data-theme="filled_blue"
                  data-shape="circle"
                  data-logo_alignment="left">
              </div>
          </div>
      </div>
  </div>
</div>
