import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExecutiveToken } from '../../../interfaces/executive-token.interface';
import { StorageService } from '../../../services/storage.service';
import { LoaderService } from '../../../services/loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';
import { TokenResponse } from '../../../interfaces/token-interface';
import { LoginLatamService } from '../../../services/login-latam.service';
import { LoginService } from '../../..//services/login.service';


@Component({
  selector: 'app-login-latam-mobile',
  templateUrl: './login-latam-mobile.component.html',
  styleUrls: ['./login-latam-mobile.component.css']
})
export class LoginLatamMobileComponent {

  executiveApprovalInfo: ExecutiveToken;
  clientId: string = StorageService.getClientId();
  executiveActionKey: string;

  constructor(private route: ActivatedRoute,
    private loaderService: LoaderService,
    private loginLatamService: LoginLatamService,
    private loginService: LoginService,
    private router: Router
  ) { }

  
  ngOnInit() {
    this.checkRedirectToExecutiveManagement();
    
    (window as any).handleCredentialResponse = (response: any) => {
      const decodedTokenTemp: TokenResponse = jwt_decode(response.credential);

      this.storeUserData(decodedTokenTemp, response.credential);

      this.loaderService.show();

      this.loginLatamService.isAuthorized().subscribe({
      next: (resp) => this.onAuthorizationSuccess(resp),
      error: (error: HttpErrorResponse) => console.log(error)
      });
    };
  }


  private storeUserData(decodedToken: TokenResponse, credential: string): void {
      localStorage.setItem('userN', decodedToken.name);
      localStorage.setItem('userMail', decodedToken.email);
      localStorage.setItem('idToken', credential);
  }

  private onAuthorizationSuccess(resp: any): void {
    localStorage.setItem('sessionToken', resp.sessionToken);
    this.loginService.setCurrentLoginState(true);
    if (this.executiveActionKey !== null && this.executiveActionKey !== ''){
      this.router.navigate([`/latam/executive/management/${this.executiveActionKey}`]).then(() => {
        this.loaderService.hide();
      });
    } else{
      this.router.navigate(['/latam/home']).then(() => {
        this.loaderService.hide();
      });
    }
  }

  private checkRedirectToExecutiveManagement(){
    this.executiveActionKey = this.route.snapshot.queryParamMap.get('actionKey');
  }
}
